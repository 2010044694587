import { Component } from 'solid-js';

const App: Component = () => {
    return (
        <div class="w-full h-full">
            Tips
        </div>
    );
};

export default App;
